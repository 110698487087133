import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { IconsDemo, P, Link, ComponentLinks } from './common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "иконки"
    }}>{`Иконки`}</h1>
    <ComponentLinks figma="https://www.figma.com/file/raJ1JI1lfRDJ0ctGj7Rpgf/hse_icons_library?node-id=1955%3A901" storybook="/?path=/story/icons-icon--all-icons" vueStorybook="/?path=/story/icons-icon--all-icons" mdxType="ComponentLinks" />
    <p>{`На данной странице представлены все иконки дизайн-системы.`}</p>
    <p>{`Для вставки иконок в проекте с React или Vue, используйте компонент `}<a parentName="p" {...{
        "href": "/src-components-icon"
      }}>{`Icon`}</a>{`.`}</p>
    <p>{`Вы можете сделать иконку кликабельной, используя компоненты `}<a parentName="p" {...{
        "href": "/src-components-icon-button"
      }}>{`IconButton`}</a>{` или `}<a parentName="p" {...{
        "href": "/src-components-clickable-icon"
      }}>{`ClickableIcon`}</a>{`.`}</p>
    <p>{`Иконка favicon расположена на `}<a parentName="p" {...{
        "href": "/logo#%D1%84%D0%B0%D0%B2%D0%B8%D0%BA%D0%BE%D0%BD"
      }}>{`странице логотипов`}</a>{`.`}</p>
    <h2 {...{
      "id": "собственные-иконки"
    }}>{`Собственные иконки`}</h2>
    <p>{`Если в Дизайн-системе не хватает нужной вашему продукту иконки, то вы можете реализовать ее в виде компонента на уровне проекта,
и использовать совместно с нашим компонентом `}<inlineCode parentName="p">{`Icon`}</inlineCode>{`.`}</p>
    <p>{`Для полноценной совместимости убедитесь, что иконка монохромная и использует цвет `}<a parentName="p" {...{
        "href": "https://css-tricks.com/currentcolor/"
      }}><inlineCode parentName="a">{`currentColor`}</inlineCode></a>{` в качестве основного,
и обработайте svg файл с помощью утилиты `}<a parentName="p" {...{
        "href": "https://github.com/svg/svgo"
      }}>{`svgo`}</a>{` с параметром `}<inlineCode parentName="p">{`removeViewBox=disabled`}</inlineCode>{`.`}</p>
    <p>{`Чтобы преобразовать svg-файл в компонент для React проектов используйте `}<a parentName="p" {...{
        "href": "https://github.com/gregberge/svgr"
      }}>{`svgr`}</a>{` (поддерживается в create-react-app из коробки),
а для Vue проектов - `}<a parentName="p" {...{
        "href": "https://github.com/visualfanatic/vue-svg-loader"
      }}>{`vue-svg-loader`}</a>{`.`}</p>
    <p>{`Если внутри svg-файла иконки используются идентификаторы (например для масок или градиентов), то они должны быть глобально уникальными.
Иначе возможны конфликты между разными иконками, так как они вставляются инлайн.
Рекомендуем добавить идентификаторам префикс в виде полного наименования иконки.`}</p>
    <p>{`Если вы считаете, что ваша иконка может быть полезна другим продуктам, то `}<a parentName="p" {...{
        "href": "/contacts"
      }}>{`напишите нам`}</a>{`, и мы добавим ее в ДС.`}</p>
    <p>{`Для импорта собственных иконок в проектах на основе create-react-app используйте следующую конструкцию:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { ReactComponent as IconMyIcon } from './myIcon.svg';

function MyComponent() {
  return <Icon icon={IconMyIcon} />;
}
`}</code></pre>
    <h2 {...{
      "id": "список-иконок"
    }}>{`Список иконок`}</h2>
    <IconsDemo mdxType="IconsDemo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      